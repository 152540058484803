<template>
  <b-modal
    id="mark-as-spam-modal"
    ref="mark-as-spam-modal"
    title="Mark As Spam"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! Level -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="level"
              rules="required"
            >
              <HeroVueSelectText
                id="level"
                v-model="level"
                label="Level"
                column="4"
                :required="true"
                :clearable="false"
                :options="levelOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Reason -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="reason"
              rules="required"
            >
              <HeroTextarea
                id="reason"
                v-model="reason"
                label="Reason"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelectText from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroTextarea,
    HeroVueSelectText,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editId: {
      type: String,
      required: true,
    },
    editItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      levelOptions: [
        { text: 'Heroleads', value: 'Heroleads' },
        { text: 'Account', value: 'Account' },
        { text: 'Campaign', value: 'Campaign' },
      ],
      level: 'Campaign',
      reason: '',
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['mark-as-spam-modal'].hide()
    },

    async validationForm() {
      let alertResult

      const axiosConfig = AxiosConfig.getLeadManagementConfig()
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = {
          leadId: this.editId,
          level: this.level,
          reason: this.reason,
          createdBy: store.getters['heroAiAuthentications/fullName'],
        }

        try {
          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axios.post(`${process.env.VUE_APP_LEAD_SERVICE_API}/lead-management/mark-as-spam`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$refs['mark-as-spam-modal'].hide()
            this.$emit('success')
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
