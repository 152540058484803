<template>
  <b-modal
    id="call-record-modal"
    ref="call-record-modal"
    title="Call Record"
    centered
    hide-footer
    @show="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <b-row>
      <b-col cols="12">
        <HeroInputText
          id="submitted-date"
          v-model="editItem.submittedDateTime"
          label="Submitted Date"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12">
        <HeroInputText
          id="did-number"
          v-model="editItem.didPhone"
          label="DID Number"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12">
        <HeroInputText
          id="phone-number"
          v-model="editItem.phone"
          label="Phone Number"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12" class="text-center">
        <hr>
        <audio
          :src="audioSource" type="audio/mpeg"
          autoplay
          preload="auto"
          controls
          controlsList="nodownload"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    HeroInputText,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    audioUrl: {
      type: String,
      required: true,
    },
    editItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      audioId: '',
      audioSource: '',
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['call-record-modal'].hide()
    },

    async doLoadData() {
      if (this.campaignId && this.audioUrl) {
        this.showOverlay = true

        // แยก Audio Id ออกจากชื่อไฟล์บน S3
        let tempId = this.audioUrl
        tempId = tempId.replace('https://heroleads-audio.s3.ap-southeast-1.amazonaws.com/thailand/', '')
        tempId = tempId.replace('https://heroleads-audio.s3.ap-southeast-1.amazonaws.com/indonesia/', '')
        tempId = tempId.replace('.mp3', '')
        this.audioId = tempId

        const token = this.$store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        try {
          const url = `/call-record/get-public-call-record-link/${this.audioId}?campaignId=${this.campaignId}`
          const response = await axios.get(url, axiosConfig)

          this.audioSource = response.data.link
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
        }
      }

      this.showOverlay = false
      return null
    },
  },
}
</script>
